@import "../../../scss/global.scss";

$menu-border-radius: 30px;

.ecua-menu {
  color: black;
  font-size: 30px;
  padding: 7px;
  border-radius: 2px;

  span {
    font-size: 20px;
  }
  cursor: pointer;
  background-color: $ecuaYellow;
}

@media only screen and (max-width: 500px) {
  .ecua-menu {
    color: black;
    font-size: 30px;
    padding: 7px;
    margin-top: 7px;
    margin-bottom: 7px;
    border-radius: 2px;

    span {
      font-size: 20px;
    }
    cursor: pointer;
  }
}

.hamburger-close {
  padding: 16px;
  background-color: $ecuaBlue;
  color: white;
}

.hamburger-close:hover {
  background-color: $ecuaRed;
  cursor: pointer;
}
.hamburger-menu-hide {
  animation: menuEnd 0.7s ease-in-out forwards !important;
}
.hamburger-menu-show {
  animation: menuStart 0.5s ease-in-out forwards !important;
}

$black-opaco: rgba(0, 0, 0, 0.1);

.hamburger-menu {
  padding: 0 !important;
  position: fixed;
  height: 100%;
  background-color: $ecuaBlue;
  display: flex;
  flex-direction: column;
  text-align: center;
  top: 0;
  right: 0;
  z-index: 999;
  border: 2px solid $black-opaco;

  li a {
    display: block !important;
    color: white;
    text-align: center;
    text-decoration: none;
    margin: 0;
    padding-right: 75px;
    padding-left: 75px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  li a:hover {
    background-color: $ecuaYellow;
    border-radius: 0px;
    color: black;
  }
}

.hamburger-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

@keyframes menuStart {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}

@keyframes menuEnd {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}
