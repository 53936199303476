@import "../../scss/global.scss";

.text-intro {
  font-size: 22px;
}

@media (max-width:768px){
  .image-content-home {
    height: 20%;
    width: 30%;
  }

  .img-fluid-new {
    height: 150px;
    width: 110px;
    
  }
}

.image-content-home {
  display: flex;
  justify-content: center;
  height: 40%;
  width: 50%;
}


.img-fluid-new {
  height: 245px;
  width: 193px;
  padding-top: 1em;
  padding-bottom: 1em;
}

.text-content {
  min-height: 317px;
  padding-top: 2em;
}
