$ecuaYellow: #ffdd47;
$ecuaBlue: #026ca1;
$ecuaRed: #ff7373;
$ecuaGreen: #477641;
$ecuaGray: #ededed;

$oro: #ffd700;
$plata: #c0c0c0;
$bronze: #cd7f32;

$ecuaBlue-light: #009ae7;

.text-blue {
  color: $ecuaBlue;
}

.ecua-container {
  background-color: white;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}
