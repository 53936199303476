.question-image {
  object-fit: contain;
  height: 100%;
}

.lupa {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

/* Tooltip text */
.tooltipImage .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  top: -190;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipImage:hover .tooltiptext {
  visibility: visible;
}
