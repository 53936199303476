@import "../../../scss/global.scss";
.imagen-opcion {
  height: 90px;
  width: auto;
}

.current {
  transition: 0.3s all;
  &-selected {
    background-color: $ecuaBlue;
    color: white;
  }
  &-not-selected {
    background-color: white;
    color: black;
  }
}
