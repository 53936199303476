@import "../../scss/global.scss";

.ecua-offline {
  background-image: url("../../assets/circuit-board.svg");
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  h1 {
    color: $ecuaBlue;
    font-weight: bold;
  }
}
