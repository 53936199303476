.required-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.required-label::after {
  content: "*";
  color: red;
}
