@import "../../scss/global.scss";
.button {
  &-blue {
    background-color: $ecuaBlue;
  }
  &-green {
    background-color: $ecuaGreen;
  }
  &-yellow {
    background-color: $ecuaYellow;
  }
  &-red {
    background-color: $ecuaRed;
  }
  &-white {
    background-color: white;
    color: $ecuaBlue;
  }
  &-gray {
    background-color: $ecuaGray;
    color: $ecuaBlue;
  }
  &-white:hover {
    color: $ecuaBlue;
  }
}

.button-disabled:disabled {
  background-color: grey;
  color: white;
}

.ecua-button {
  background-position: center;
  transition: background 0.8s;
  border: none;
  border-radius: 2px;
  padding: 12px 18px;
  text-transform: uppercase;
  cursor: pointer;
  color: black;
  outline: none;
  position: relative;
  overflow: hidden;
}

.button-ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  background: white;
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

.button-ripple-white {
  width: 20px;
  height: 20px;
  position: absolute;
  background: $ecuaBlue;
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.content {
  position: relative;
  z-index: 2;
}
