@import "../../scss/global.scss";

.text-intro {
  font-size: 22px;
}

.competencia-container {
  background-color: white;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.informacion-container {
  background-color: rgba($ecuaYellow, 0.5);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.3);
}

.comunicacion-container {
  background-color: rgba($ecuaBlue-light, 0.5);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.3);
}

.contenido-container {
  background-color: rgba($ecuaRed, 0.5);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.3);
}

.texto{ 
  text-align: justify; 
}

.texto-lista{ 
  text-align: justify;
  display: inline-block; 
  width: 100%;
}

.imagenes{
  text-align: center;
}

@media (max-width: 990px){
  .img-fluid2 {
    max-height: 250px;
    max-width: 100%;
    width: 100%;
    height: auto;
    display: inline-block;
  }
  
  .condor{
    width: 200px;
    max-width: 100%;
  }

  .jaguar{
    width: 200px;
    max-width: 100%;
  }

  .tortuga{
    width: 200px;
    max-width: 100%;
  }
}

@media (min-width: 991px){
  .img-fluid2 {
    max-height: 350px;
    max-width: 100%;
    width: 100%;
    height: auto;
    display: inline-block;
  }

  .condor {
    width: 250px;
    height: 250px;
    float: left;
    padding: 6px;
    margin-right:10px;
  }

  .jaguar {
    width: 250px;
    height: 250px;
    float: right;
    padding: 6px;
    margin-left:10px;
  }
  
  .tortuga {
    width: 250px;
    height: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
}