@import "../../scss/global.scss";
.ecua-footer {
  background-color: #04557e;
  width: 100%;
  min-height: 200px;
}

.ecua-footer-flag {
  display: flex;
  flex-direction: row;
  span:nth-child(1) {
    display: block;
    width: 50%;
    height: 10px;
    background-color: $ecuaYellow;
  }
  span:nth-child(2) {
    display: block;
    width: 25%;
    height: 10px;
    background-color: $ecuaBlue;
  }
  span:nth-child(3) {
    display: block;
    width: 25%;
    height: 10px;
    background-color: $ecuaRed;
  }
}

.ecua-footer-text {
  background-color: black;
  padding: 50px 100px;
}
