@import "../../scss/global.scss";
.ecua-navbar {
  background-color: $ecuaBlue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //padding-top: 1rem;
  //padding-bottom: 1rem;

  div {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  ul.ecua-ul-navbar {
    display: flex;
    align-items: center;
    gap: 3em;
    margin: 0;
    li {
      list-style-type: none;

      a {
        display: block;
        text-decoration: none;
        color: White;
        transition: color 0.3s;
      }
      a:hover {
        text-decoration: none;
        color: $ecuaYellow;
      }
    }
  }
}
