/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 1, 2022 */

@font-face {
  font-family: "gothammedium";
  src: url("gothammedium_1-webfont.woff2") format("woff2"),
    url("gothammedium_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight_italic";
  src: url("gothamlightitalic-webfont.woff2") format("woff2"),
    url("gothamlightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight";
  src: url("gothamlight-webfont.woff2") format("woff2"),
    url("gothamlight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook_italic";
  src: url("gothambookitalic-webfont.woff2") format("woff2"),
    url("gothambookitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook";
  src: url("gothambook-webfont.woff2") format("woff2"),
    url("gothambook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold_italic";
  src: url("gothambolditalic-webfont.woff2") format("woff2"),
    url("gothambolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold";
  src: url("gothambold-webfont.woff2") format("woff2"),
    url("gothambold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamxlightitalic";
  src: url("gotham-xlightitalic-webfont.woff2") format("woff2"),
    url("gotham-xlightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamxlight";
  src: url("gotham-xlight-webfont.woff2") format("woff2"),
    url("gotham-xlight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamultraitalic";
  src: url("gotham-ultraitalic-webfont.woff2") format("woff2"),
    url("gotham-ultraitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
