.presentation-container {
  width: 100%;
  margin-bottom: 15px;
}

.presentacion-content {
  padding: 0 20px;
}

.separator {
  border-bottom: 0.2px solid #acacac;
}

.pregunta-container {
  margin: 10px 30px;
}

.bottom-button {
  display: flex;
  justify-content: end;
}

