@import "../../scss/global.scss";

.titulo-form {
  color: $ecuaBlue;
}

.required-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.required-label::after {
  content: "*";
  color: red;
}
