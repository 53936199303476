@import "../../scss/global.scss";
.evaluacion-container {
  background-color: white;
  box-shadow: 0px 0px 4px 2px rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  transform: translateY(-2rem);
}

.evaluacion-container-footer {
  background-color: rgba($color: $ecuaBlue, $alpha: 0.25);
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 20px 15px;
  display: flex;
  justify-content: center;
}
