@import "../../scss/global.scss";

.sobre-nosotros-title {
  color: $ecuaBlue;
}

.image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.nosotros-container {
  background-color: white;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

@media only screen and (max-width: 768px) {
  .image-container {
    flex-direction: column;
  }
}
