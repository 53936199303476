@media (max-width: 500px) {
  img.imagen-opcion {
    width: 50%;
    height: 50%;
  }
}

@media (max-width: 700px) and (min-width: 501px) {
  img.imagen-opcion {
    width: 60%;
    height: 60%;
  }
}

@media (min-width: 700px) {
  img.imagen-opcion {
    width: 100%;
    height: 100%;
  }
}

.lupa {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

/* Tooltip text */
.tooltipImage .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  top: -190;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipImage:hover .tooltiptext {
  visibility: visible;
}
