.ecua-message {
  margin-left: 25px;
}

.ecua-list {
  margin: 0px 2px;
}

.ecua-navigation {
  text-transform: unset !important;
}

.ecua-button-container {
  margin-top: 40px;
}
