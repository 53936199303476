@import "../../scss/global.scss";

.modal-background {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: appear 0.3s ease-in-out;
}

@keyframes appear {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.ecua-modal {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.39);
  width: clamp(80%, 900px, 80%);
  min-height: 20%;
  max-height: 80%;
  height: clamp(20%, auto, 80%);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  overflow-y: auto;
  animation: appearModal 0.3s ease-in-out forwards;
}

@keyframes appearModal {
  0% {
    top: 75%;
  }
  100% {
    top: 50%;
  }
}

.ecua-modal-close {
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;

  svg {
    $size: 25px;
    background-color: #d9d9d9;
    border-radius: 50%;
    color: black;
    width: $size;
    height: $size;
    padding: 0.25rem;
    z-index: 99;
    cursor: pointer;
  }
}
