@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "../assets/GothamFont/stylesheet.css";
body {
  margin: 0;
  font-family: "gothambook", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "gothambold", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
