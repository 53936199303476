@import "../../scss/global.scss";
.ecua-intro {
  .ecua-wave {
    fill: $ecuaYellow;
    fill-opacity: 1;
    transform: translate(0, -130%);
  }
  .ecua-wave-2 {
    fill: rgba($ecuaYellow, $alpha: 0.5);
    transform: translate(0, -70%);
  }
}

.ecua-intro-container {
  background-color: $ecuaYellow;
  h1,
  p {
    margin: 0;
  }
}
.intro-titulo {
  padding-top: 1rem;
  max-width: 15ch;
  color: $ecuaBlue;
  font-weight: 550;
  font-size: 3rem;
}
.intro-subtitulo {
  max-width: 50ch;
}

@media screen and (max-width: 768px) {
  .intro-titulo {
    max-width: 100%;
    color: $ecuaBlue;
    font-weight: 550;
    text-align: center;
    font-size: 2rem;
  }
  .intro-subtitulo {
    max-width: 100%;
    text-align: center;
  }
  .ecua-intro-container {
    padding-top: 5rem;
  }
}

.text-intro-boton {
  display: block;
  padding: 2px;
  font-size: 15px;
}

/* Button animation*/
.boton-evaluate {
  color: white; /* color de fuente */
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  .text-intro-boton {
    font-size: 20px;
  }
}
.boton-evaluate:hover {
  color: blue; /* color de fuente hover */
}

.boton-evaluate::after {
  content: "";
  background: white; /* color de fondo hover */
  position: absolute;
  z-index: -1;
  display: block;
  border-radius: 50%;
  left: -50%;
  right: -50%;
  top: -150%;
  bottom: -150%;
  transform: scale(0, 0);
  transition: all 0.4s ease;
}
.boton-evaluate:hover::after {
  color: blue;
  transition: all 0.6s ease-out;
  transform: scale(1, 1);
}
