@import "../../scss/global.scss";
@mixin nivel-font {
  font-size: 2.5rem;
  padding: 5px 15px;
  border-radius: 15px;
}

.nivel {
  &-inicial {
    @include nivel-font;
    background-color: $plata;
    box-shadow: 0px 0px 10px 2px rgba($plata, 0.4);
  }

  &-basico {
    @include nivel-font;
    background-color: $bronze;
    box-shadow: 0px 0px 10px 2px rgba($bronze, 0.4);
  }

  &-intermedio {
    @include nivel-font;
    background-color: $plata;
    box-shadow: 0px 0px 10px 2px rgba($plata, 0.4);
  }
  &-avanzado {
    @include nivel-font;
    background-color: $oro;
    box-shadow: 0px 0px 10px 2px rgba($oro, 0.4);
  }
}
