.multimedia-container {
  height: 900px;
}

@media only screen and (max-width: 788px) {
  .multimedia-container {
    height: 700px;
  }
  .resource-container {
    display: flex;
    flex-direction: column;
  }
  .simulation-container {
    display: inline;
    width: 100%;
  }
}

@media (min-width: 789px) and (max-width: 1000px) {
  .multimedia-container {
    height: 700px;
  }
  .resource-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1001px) and (max-width: 1200px) {
  .multimedia-container {
    height: 700px;
  }
}
