@import "../../scss/global.scss";

.tooltiptext {
  width: 250px;
  background-color: #fff;
  border: 1px solid $ecuaBlue;
  box-shadow: 0 0 2px 2px #cacaca;
  color: #0f0c0c;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 10%;
}

.tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $ecuaBlue transparent transparent transparent;
}
