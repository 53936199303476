@import "../../scss/global.scss";
.ecua-card {
  background-color: white;
  border: 5px solid rgba(255, 221, 71, 0.01);
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 30px 20px;
  text-align: center;
  flex: 1;
  height: 100%;
  gap: 10px;
  .ecua-title {
    font-weight: bold;
    font-size: 21px;
    color: $ecuaBlue;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .ecua-description {
    font-size: 21px;
    height: 50%;
    padding-bottom: 1em;
    text-align: justify;
  }
}

.text {
  text-align: left;
  font-weight: normal !important;
  font-size: 2em;
}

.image-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.img-design {
  height: 80%;
}
