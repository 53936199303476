@import "../../scss/global.scss";

.main-title {
  position: relative;
  max-width: 1900px !important;
  margin-left: auto;
  margin-right: auto;
}

.download-button {
  position: absolute;
  bottom: 25%;
  right: -23%;
  font-size: 10px;
}

@media only screen and (max-width: 995px) {
  .download-button {
    position: relative;
    bottom: 0;
    right: 0;
  }
  .main-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
}
@media (min-width: 995px) and(max-width: 1400px) {
  .download-button {
    position: absolute;
    bottom: 25%;
    right: -5%;
  }
}

@mixin copaEstilo {
  height: 150px;
  width: auto;

  @media only screen and (max-width: 768px) {
    height: 100px;
    width: auto;
  }
}
.copa {
  &-oro {
    @include copaEstilo;
    fill: $oro;
  }
  &-plata {
    @include copaEstilo;
    fill: $plata;
  }
  &-bronze {
    @include copaEstilo;
    fill: $bronze;
  }
}

.break-page {
  display: none;
}

.ecua-courses-list {
  display: none;
}

@media print {
  .download-button {
    display: none;
  }
  .ecua-navigation {
    display: none;
  }
  .break-page {
    display: block;
    height: 300px;
  }
  .ecua-courses-list {
    display: block;
  }
}
