@import "../../scss/global.scss";

.green-icon {
  color: $ecuaGreen;
  font-size: 25px;
  padding-right: 5px;
}
.red-icon {
  color: $ecuaRed;
  font-size: 25px;
  padding-right: 5px;
}
.orange-icon {
  color: orange;
  font-size: 25px;
  padding-right: 5px;
}
.yellow-icon {
  color: $ecuaYellow;
  font-size: 25px;
  padding-right: 5px;
}
.icon-text-ecu {
  display: grid;
  grid-template-columns: 1fr 10fr;
  align-items: center;
  margin: 1rem 0px;
}
