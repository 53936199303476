@import "../../scss/global.scss";

.ecua-dropitem {
  display: grid;
  grid-template-columns: 1fr 10fr 0.5fr;
  gap: 2rem;
  text-align: justify;
}

@media only screen and (max-width: 488px) {
  .ecua-dropitem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0rem;
  }
}

@mixin puntaje {
  padding: 0.5rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ecua-puntaje {
  &-completo {
    @include puntaje;
    border: 3px solid $ecuaGreen;
  }
  &-intermedio {
    @include puntaje;
    border: 3px solid $ecuaYellow;
  }
  &-bajo {
    @include puntaje;
    border: 3px solid $ecuaRed;
  }
}

.ecua-arrow {
  font-size: 30px;
  svg {
    cursor: pointer;
  }
}

.ecua-drop-animation {
  height: 0;
  overflow: hidden;
  transition: height ease 0.5s;
}
