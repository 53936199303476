@import "../../scss/global.scss";
.ecua-404 {
  background-image: url("../../assets/circuit-board.svg");
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  h3 {
    color: $ecuaBlue;
    font-weight: bold;
  }
  .ecua-404-error {
    font-size: 100px;
    color: $ecuaRed;
    font-weight: bold;
  }
}
