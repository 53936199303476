@import "../../../scss/global.scss";
.ecua-question-header {
  background-color: $ecuaBlue;
  min-height: 35vh;
  position: relative;
}
.ecua-rectangle-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  overflow-x: hidden;
  height: clamp(400px, 45vh, 800px);
  background-color: $ecuaBlue;
}
