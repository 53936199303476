@import "../../scss/global.scss";
$radius: 20px;

.ecua-progress {
  background-color: #f1f1f1;
  display: flex;
  width: clamp(50%, 500px, 80%);
  height: 40px;
  border-radius: $radius;
  position: relative;
}

.ecua-progress-bar {
  background-color: #f1f1f1;
  height: 100%;
  transition: all 0.3s ease;
  border: 0.1px solid #f1f1f1;
}

.ecua-progress-bar:hover {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}

.ecua-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ecua-progress-bar {
  position: relative;
}

.ecua-progress-bar p {
  margin: 0px;
}

.ecua-progress-bar .top {
  min-width: 130px;
  top: -20px;
  left: 50%;
  transform: translate(-30%, -100%);
  padding: 5px 10px;
  color: #ffffff;
  background-color: #009cdc;
  font-weight: normal;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
}

.ecua-progress-bar:hover .top {
  display: block;
}

.ecua-progress-bar .top i {
  position: absolute;
  top: 100%;
  left: 30%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
}

.ecua-progress-bar .top i::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #009cdc;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
