@import "../../scss/global.scss";
.question-container {
  background-color: white;
  box-shadow: 0px 0px 4px 2px rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
}

.question-container-footer {
  background-color: rgba($color: $ecuaBlue, $alpha: 0.25);
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
}

.speaker-icon {
  width: 26px;
  height: auto;
  padding: 2px;
}

.speaker-container svg {
  background-color: $ecuaYellow;
  border-radius: 2px;
  width: 40px;
}

.question-container svg:hover {
  cursor: pointer;
}

.button-container {
  display: flex;
  gap: 1rem;
}

@media (max-width: 519px){
  img[alt="Question Resources"]{
    width: 375px;
  }
}

@media (min-width:520px) and (max-width: 765px){
  img[alt="Question Resources"]{
    width: 500px;
  }
}


@media (max-width: 999px) and (min-width: 766px) {
  img[alt="Question Resources"]{
    width: 675px;
  }
}


@media (min-width: 1000px) { 
  img[alt="Question Resources"]{
    width: 800px;
  }
}


