.main-container {
  background-image: url("../../assets/circuit-board.svg");
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.children-container {
  min-height: 100vh;
}

.footer-text {
  h4,
  p {
    margin: 0;
    color: white;
    text-align: center;
  }
  h4 {
    font-weight: bold;
  }
  gap: 10px;
}
.footer-svg {
  svg {
    fill: white;
    width: 100%;
    height: 40px;
  }
  gap: 10px;
}
.footer-logo {
  gap: 20px;
}

.wrapper-responsive {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1142px) {
  .wrapper-responsive {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
