.ecua-label {
  background-color: #ffdd47;
  bottom: 0px;
  padding: 15px 40px;
}

.ecua-wrapper {
  position: relative;
  width: 100%;
}

.ecua-text {
  color: black;
  font-weight: 1000;
  margin: 0px;
}

@media only screen and (max-width: 488px) {
  .ecua-text {
    font-size: 10px;
    font-weight: 500;
    margin: 0px 15px;
  }
  .ecua-label {
    padding: 0px;
  }
}
